import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
// import { withPrefix } from "gatsby"
import callout from '../../../images/savings_pg_callout.png'

const metaTags = {
  description: 'Read about the SUPPRELIN® LA SHARES program and what patients may qualify.',
  keywords: 'Homepage',
  title: 'SHARES Savings Program | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col center='xs'>
        <h1>The SUPPRELIN<sup>&reg;</sup> LA SHARES Copay Assistance Program</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>Don’t let a copay stand in your way</h2>
        <p>Eligible patients may save up to $6,000 on each SUPPRELIN<sup>&reg;</sup> LA prescription after a $0 copay. The SUPPRELIN<sup>&reg;</sup> LA Support Center will contact you if your child is eligible for this copay assistance program.</p>
        <p>The majority of patients enrolled in the SHARES Copay Assistance Program pay $0 a year for SUPPRELIN<sup>&reg;</sup> LA. For those who exceed the $6,000 amount, they may be eligible for additional assistance. </p>
        <p>Restrictions may apply. See terms and conditions.*</p>
      </Col>
      <Col xs={12} md={6}>
        <img src={callout} alt="Support Center Contact Info" title="Support Center Contact Info" />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p className="footnote asterik">Offer good only in the USA and void where prohibited or otherwise restricted by law. Offer not valid for prescriptions that may be reimbursed under a federal or state healthcare program, including Medicare, Medicaid, or any other federal or state healthcare programs, including any state medical pharmaceutical assistance programs. Patients over the age of 18 are not eligible. Endo has the right to rescind, revoke, or amend this program without notice. This offer is not transferable.</p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
      <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/bb39c5e5-b03c-40fb-b52b-3110919e08ff/bb39c5e5-b03c-40fb-b52b-3110919e08ff_source__v.pdf"
          LinkCaption="Download important information about CPP and how SUPPRELIN<sup>&reg;</sup> LA can help - shares savings program"
        >
              Download important information about CPP and how SUPPRELIN<sup>&reg;</sup> LA can help
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
